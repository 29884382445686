.preferences-menu {
    display: flex;
    flex-direction: column;
    padding: 10px;
}

.preferences-menu-main {
    width: 200px;
    line-height: 23px;
    border: 'none',
}

.preference-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.preference-label {
    flex: 1;
    margin-right: 10px;
}

.preference-slider {
    width: 150px; /* Adjust width as needed */
}

#preferences {
    .ant-menu-submenu-active::after {
        border: none
    }
}

input[type="range"][orient="vertical"] {
    writing-mode: bt-lr;
    appearance: slider-vertical;
}

.visualization-controls {
    position: absolute;
    top: 1rem;
    right: 1rem;
}

$small: 576px;

#topbar {
    z-index: 999;
    width: 100vw;
    position: relative;
    @media screen and (max-width: $small) {
        position: fixed;
        left: 0;
        bottom: 0;
    }
}

#sidebar {
    position: relative;
    z-index: 100;
    @media screen and (max-width: $small) {
        display: none;
    }
}

#container {
    display: flex;
    height: calc(100vh - 50px);
    width: 100vw;
}

#content-container {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    min-width: 0;
    height: calc(100vh - 50px);
    overflow-y: auto;
    @media screen and (max-width: $small) {
        height: calc(100vh);
    }
}

#header {
    width: 100%;
    flex: 0 0 auto;
    @media screen and (max-width: $small) {
        display: none;
    }
}

#content {
    flex: 1;
    padding-top: 10px;
    padding-bottom: 25px;
    padding-right: 25px;
    padding-left: 25px;
    //overflow-y: auto;
    display: block;
    max-width: 100%;
    @media screen and (max-width: $small) {
        width: 100vw;
        margin: auto;
        margin-top: 0vh;
        margin-bottom: 3.5rem;
        padding-top: 0;
        padding-right: 0.5px;
        padding-left: 0.5px;
        height: 100vh;
    }
}

/* AutoSuggest.scss */
.autosuggest-container {
    position: relative;
  }
  
  .autosuggest-input {
    width: 300px;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .autosuggest-suggestions-container {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 999;
    max-height: 200px;
    overflow-y: auto;
    border: 1px solid #ccc;
    background-color: #fff;
  }
  
  .autosuggest-suggestions-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .autosuggest-suggestion {
    padding: 10px;
    cursor: pointer;
  }
  
  .autosuggest-suggestion:hover {
    background-color: #f2f2f2;
  }
  
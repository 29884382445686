/* Add this to your global or component-specific CSS */
@media (max-width: 768px) {
    .datepicker-mobile {
        width: 120px !important;
    }
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    z-index: -1 !important;
}
  
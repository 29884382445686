.w-s-1 {
    width: 1.4rem;
}

.h-s-1 {
    height: 0.3rem;
}

.h-dashed {
    height: 0.25rem;
}

.w-dashed {
    width: 0.25rem;
}

.rounded-dashed {
    border-radius: 0.12rem;
}

.mr-dashed {
    margin-right: 0.2rem;
}
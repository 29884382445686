$color-grey: #ebecf0;

#hover {
    font-size: 20;
    :hover {
        background-color: $color-grey !important;
    }
}

#sidebar-overlay {
    background-color: #ffffff;
    top: 2px;
    position: absolute;
    height: 30px;
    width: calc(100% - 2px);
}

#sidebar-footer {
    background-color: #95d1be;
    bottom: 0px;
    position: absolute;
    height: 8px;
    width: calc(100% - 2px);
    border-radius: 0px 10px 0px 0px;
}
#signout {
    bottom: 10px;
    position: absolute;
    width: 100%;
}

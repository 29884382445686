// @import './node_modules/react-vis/dist/style';

body {
    overflow-y: hidden;
    height: 100vh;
}

.circle-shadow {
    box-shadow: 0 5px 10px 7.5px #dedede;
}

.mybutton.ant-radio-button-wrapper {
    height: 64px;
    width: 64px;
}
@tailwind base;
@tailwind components;
@tailwind utilities;

svg {
    vertical-align: baseline;
}

.ant-table {
    height: 100%;
}

.ant-table > .ant-table-container {
    height: 100%;
}

.ant-table > .ant-table-container > .ant-table-content {
    height: 100%;
    max-height: 40rem;
    overflow: auto;
}

.ant-spin-nested-loading {
    height: 100%;
}

.ant-spin-nested-loading > .ant-spin-container {
    height: 100%;
}

.ant-card > .ant-card-body {
    height: 100%;
}

.ant-tabs.ant-tabs-top.ant-tabs-card {
    height: 100%;
}

.ant-tabs-content-holder {
    display: flex;
    flex-direction: column;
}

.ant-tabs-content.ant-tabs-content-top {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.no-padding-tab {
    .ant-card-body {
        padding: 0;
    }
}